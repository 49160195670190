<template>
    <NuxtPage />
    <PlayerSmall />
</template>

<script setup lang="ts">

const colorMode = useColorMode()
const colorModeQuery = useRoute().query.theme as string
const primaryColor = useRoute().query.color as string

const color = ref<'dark' | 'light'>(colorModeQuery)

const { primaryColor: primary } = useEmbedded()

watchEffect(() => {
    colorMode.preference = color.value
    primary.value = `#${primaryColor}`
})

</script>

<style></style>